

@media screen  and (max-width: 768px){
    /* N A V B A R */

   .navlist-right{
        flex-direction: column;
        align-items: flex-end;
   }

   .social__links{
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    align-items: center;
   }

   .desktop__nav{
    display: none;
   }
}