@tailwind base;
@tailwind components;
@tailwind utilities;


 
:root{
  --primary-bg: #f60302;
  --secondary-bg: #c1224c;
  --buttons-bg: rgb(167, 45, 9);
  --headings-color: #df6688;
  --font-color: rgb(39, 10, 1);
  --light-font-color: #fff6f0;
  --items-border-bg: #fad4a7;
}

body{
  overflow-x: hidden !important;
}

a{
  text-decoration: none;
  color: black;
}

.mobile__nav{
  display: none;
}

.cakesHead{
  text-shadow: var(--light-font-color) 0px 0px 4px;
  -webkit-text-stroke: 1px var(--font-color); /* Add a 4px black stroke for webkit browsers */
  /* -webkit-text-fill-color: transparent; */
}

.button:hover ~ .second {
  @apply opacity-[0.66] delay-[50ms] -right-5;
}
.button:hover ~ .third {
  @apply opacity-[0.33] delay-100 -right-10;
}
.second,
.third {
  @apply opacity-0;
}

.card{
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.card-image{
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.card-info{
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.card:hover .card-image {
  transform: translateY(-5%);
 }
 
 .card:hover .card-info {
  transform: translateY(100%);
  opacity: 1;
 }

 .noscrollbar::-webkit-scrollbar{
  width: 0.2em; /* Set the width of the scrollbar */
}

.arrow:hover{
  color: #fff;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 25%, rgba(246,3,2,1) 50%, rgba(255,255,255,1) 75%); 
}


.css-19kzrtu{
  padding: 0 !important;
}


button .hover-text {
  display: none;
}

button:hover .default-text {
  display: none;
}

button:hover .hover-text {
  display: inline;
}

.glider-track{
  gap: 8px;
  /* padding: 0px !important; */
}

.glider-container{
  padding: 4px;
}